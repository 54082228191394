<template>
  <div class="px-2 game-score text-center">
    <div class="position-relative" v-if="game.score_side1 > game.score_side2">
      <div class="text-nowrap">
        <span :class="dataSport.textClass">{{game.score_side1}}</span>
        <span class="separator text-white">-</span>
        <span class="text-white">{{game.score_side2}}</span>
      </div>
    </div>
    <div class="position-relative" v-else-if="game.score_side1 < game.score_side2">
      <div class="text-nowrap">
        <span class="text-white">{{game.score_side1}}</span>
        <span class="separator text-white">-</span>
        <span :class="dataSport.textClass">{{game.score_side2}}</span>
      </div>
    </div>
    <div class="position-relative" v-else-if="game.score_side1 === game.score_side2 && game.state !== 'upcoming'">
      <div class="text-nowrap">
        <span class="text-white">{{game.score_side1}}</span>
        <span class="separator text-white">-</span>
        <span class="text-white">{{game.score_side2}}</span>
      </div>
    </div>
    <div v-else>
      <b class="separator text-white">-</b>
    </div>
  </div>
</template>

<script>
  export default {
    name: "MlbGameScore",
    props: ['game','dataSport'],
  }
</script>

<style scoped>
  .game-score {
    letter-spacing: 2px;
    font-size: 0.8rem;
  }
</style>